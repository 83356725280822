import { baseURLEndpoint } from './constant'

export default {
  NAME: 'hyble',
  LOGIN_TITLE: ' hyble - Login | Unlimited Graphic Design Service',
  KANBAN: 'https://mrm.staging.dflow.com.au/',
  DOMAIN: baseURLEndpoint.substring(0, baseURLEndpoint.length - 6),
  REGISTER:
    baseURLEndpoint.substring(0, baseURLEndpoint.length - 6) + 'pricing-plans',
}
